import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import "./contentblog.css";
import { Link, useParams } from "react-router-dom";
import { marked } from "marked";
import DOMPurify from "dompurify";
import { FaThumbsUp, FaComment, FaGreaterThan } from "react-icons/fa";
import ReCAPTCHA from "react-google-recaptcha";
import { Margin } from "@mui/icons-material";
import Avatar from '@mui/material/Avatar';
import { AiOutlineLike } from "react-icons/ai";
import SocialShare from "./SocialShare";

const BlogDetails = () => {
  const { title } = useParams();
  const [contentPost, setContentPost] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [likes, setLikes] = useState(0);
  const [categories, setCategories] = useState([]);
  const [isCommentsVisible, setIsCommentsVisible] = useState(false);
  const [comments, setComments] = useState([]);
  const recaptchaRef = useRef(null);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    comment: "",
    agree: false,
    recaptchaToken: "",
  });

  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://demo.expertit.in/api/blogs?filters%5Bslug%5D=${title}&populate=*`
        );
        const filteredPost = response.data.data[0];

        setLikes(parseInt(filteredPost.attributes.likes, 10));
        setContentPost(filteredPost);
        console.log(contentPost.id);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    };

    fetchData();
  }, [title]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const categoryData = await axios.get(
          "https://demo.expertit.in/api/categories?populate=*"
        );
        setCategories(categoryData.data.data);

      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []);
  useEffect(() => {
    const fetchComments = async () => {
      try {

        const response = await axios.get("https://demo.expertit.in/api/comments1");
        setComments(response.data.data);

        setIsCommentsVisible(true);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }

    };
    fetchComments();


  }, []);




  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleRecaptchaChange = (token) => {
    setFormData((prevState) => ({
      ...prevState,
      recaptchaToken: token,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (!formData.recaptchaToken) {
        alert("Please complete the reCAPTCHA verification.");
        return;
      }

      const data = {
        name: formData.name,
        Email: formData.email,
        comment: [
          {
            type: "paragraph",
            children: [
              {
                type: "text",
                text: formData.comment,
              },
            ],
          },
        ],
      };

      const response = await axios.post(
        "https://demo.expertit.in/api/comments",
        { data }
      );

      setFormData({
        name: "",
        email: "",
        comment: "",
        agree: false,
        recaptchaToken: "",
      });
      recaptchaRef.current.reset();
      alert("Comment submitted successfully!");
    } catch (error) {
      console.error("Error submitting form:", error);
      alert("Failed to submit comment. Please try again.");
    }
  };
  const handleLike = async () => {
    try {
      const newLikes = isLiked ? likes - 1 : likes + 1;
      setIsLiked(!isLiked);
      setLikes(newLikes);

      await axios.put(
        `https://demo.expertit.in/api/blogs/${contentPost.id}`,
        { data: { likes: newLikes } }
      );

    } catch (error) {
      console.error("Error updating likes:", error);
    }
  };


  if (isLoading) {
    return <p>Loading...</p>;
  }

  if (!contentPost) {
    return <p>No content found for the specified title.</p>;
  }

  const { attributes } = contentPost;
  const { Title, Subtitle, BlogDate, content } = attributes;
  const formattedDate = new Date(BlogDate).toLocaleDateString("en-GB");
  const parsedContent = marked(content);

  return (
    <div className="blog-details-container">

      {attributes.CoverImg && (
        <img
          src={`https://demo.expertit.in${Array.isArray(attributes.CoverImg.data)
            ? attributes.CoverImg.data[0].attributes.url
            : attributes.CoverImg.data.attributes.url
            }`}
          alt={Title}
          className="blog-cover-image"
        />
      )}
      <div className="blog-content">

        <div className="blog-contents">

          <h2 className="head-case">{Title}</h2>
          <p>{Subtitle}</p>
          <h6 style={{ marginTop: '15px' }}>{formattedDate}</h6>
          <div
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(parsedContent),
            }}
          />
          <hr style={{ marginTop: '25px', marginBottom: '20px', borderTop: '1px solid black' }} />


          <div className="likes-comments">
            <div className="likes">
              <AiOutlineLike size={25} style={{ color: isLiked ? "green" : "", cursor: 'pointer' }} onClick={handleLike} /> <p style={{ marginTop: "10px" }}>{likes} Likes</p>
            </div>
            <div style={{ width: '50%', display: 'flex', justifyContent: 'end' }}><SocialShare /></div>

          </div>

          <hr style={{ marginTop: '20px', marginBottom: '20px', borderTop: '1px solid black' }} />

          <form className="comment-form" onSubmit={handleSubmit}>
            <div className="comment-form-user-details">
              <input
                type="text"
                name="name"
                value={formData.name}
                placeholder="Your Name"
                onChange={handleInputChange}
                required
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                placeholder="Your Email"
                onChange={handleInputChange}
                required
              />
            </div>
            <textarea style={{ marginTop: '15px', padding: '10px', borderBottom: 'none' }}
              name="comment"
              value={formData.comment}
              placeholder="Your Comment"
              onChange={handleInputChange}
              required
            />
            <div className="form-group-captcha">
              <ReCAPTCHA
                sitekey="6LckvrYpAAAAAO3lwZ-kQM2Qa4EC5laGAy2Ma6HN"
                onExpired={() => setFormData({ ...formData, recaptchaToken: "" })}
                onChange={handleRecaptchaChange}
                ref={recaptchaRef}
              />
            </div>
            <input
              type="checkbox"
              name="agree"
              checked={formData.agree}
              onChange={handleInputChange}
              required
            />
            <label htmlFor="agree">I agree to the terms and conditions</label>
            <br />
            <button type="submit">Send</button>
          </form>

          <hr style={{ marginTop: '30px', marginBottom: '20px', borderTop: '1px solid black' }} />

          <div className="comments-section">
            <h3>Recent Comments</h3>
            {
              comments.length > 0 ? (
                comments.map((comment) => (
                  <div key={comment.id} className="comment-body">
                    <div className="comment-header">
                      <Avatar alt="Remy Sharp" src="" className="avatar" />
                      <div className="comment-info">
                        <p className="username"><strong>{comment.attributes.name}</strong></p>
                      </div>
                    </div>
                    <div className="comment-content">
                      <p className="comment-text"><strong>{comment.attributes.comment[0].children[0].text}</strong></p>
                    </div>
                  </div>
                ))
              ) : (
                <p style={{ marginTop: '15px', fontWeight: '400' }}>No comments yet.</p>
              )
            }


          </div>

        </div>

        <div className="related-links">
          <SocialShare />
          <h3>Categories</h3>
          {categories.map((category) => (
            <div key={category.id} className="categories">
              <Link to={`/category/${category.attributes.slug}`} key={category.id}>
                <li><FaGreaterThan /> {category.attributes.Title}</li>
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BlogDetails;
