import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import SideHoverModal from "./SideHoverModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdownmenu5 from "./Dropdownmenu5";
import DropdownMenu from "./DropdownMenu";
import DropdownMenu2 from "./DropdownMenu2";
import "./DropdownMenu.css"; // Import the CSS file for styling

import {
  faYoutube,
  faFacebook,
  faTwitter,
  faInstagram,
  faLinkedin,
  faGoogle, faWhatsapp
} from "@fortawesome/free-brands-svg-icons";
import DropdownMenu3 from "./DropdownMenu3";
import DropdownMenu4 from "./DropdownMenu4";


export default function NavBar() {



  document.addEventListener("DOMContentLoaded", function () {
    const whatsappIcon = document.querySelector(".whatsapp.social");
    const whatsappPopup = document.getElementById("whatsapp-popup");

    whatsappIcon.addEventListener("click", function () {
      whatsappPopup.classList.toggle("show");
    });

    whatsappIcon.addEventListener("mouseenter", function () {
      whatsappPopup.classList.add("show");
    });

    whatsappIcon.addEventListener("mouseleave", function () {
      whatsappPopup.classList.remove("show");
    });
  });


  const [activeSection, setActiveSection] = useState("default-section");
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [sideHoverModal, setSideHoverModal] = useState(false);
  const [modalPage, setModalPage] = useState("business");

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
    scrollTop > 0 ? setIsScrolled(true) : setIsScrolled(false);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    const windowWidth = window.innerWidth;
    if (windowWidth < 500) {
      const sidebar = document.getElementById("sidebar");
      const sideModal = document.getElementById("hover-modal");
      if (sideModal !== null) {
        sideModal.style.width = "100%";
      }
      sidebar.classList.add("close");
    }
  }, [sideHoverModal]);
  function scrollToSection(sectionId) {
    // Implementation of scrollToSection function, e.g., scroll to the specified section
  }

  function showDropdown() {
    const dropdown = document.getElementById("dropdownMenu");
    dropdown.classList.add("active");
  }

  function hideDropdown() {
    const dropdown = document.getElementById("dropdownMenu");
    dropdown.classList.remove("active");
  }

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      const scrollThreshold = 50; // Adjust as needed
      setIsScrolled(scrollTop > scrollThreshold);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleToggleButtonClick = () => {
    setSideHoverModal(false);
    setSidebarOpen(!sidebarOpen);
  };

  document.addEventListener("DOMContentLoaded", function () {
    var line = document.getElementById("scrollLine");

    window.addEventListener("scroll", function () {
      // Add the 'scrolled' class to the element when scrolling
      if (window.scrollY > 0) {
        line.classList.add("scrolled");
      } else {
        line.classList.remove("scrolled");
      }
    });
  });

  return (
    <div
      id="navbar-container"
      className={`${isScrolled ? "nav-background" : ""
        } container-fluid pt-3 pb-3 d-flex flex-row `}
    >
      <nav className="navbar navbar-expand-lg col-md-11 col-sm-11">
        <Link to="/">
          <img
            src={
              isScrolled
                ? "/Images/Digitechub_white_SVG_file.svg"
                : "/Images/Digitechub_white_SVG_file.svg"
            }
            id="myImage"
            width="170"
            height="100"
            onClick={() => window.scrollTo(0, 0)}
          />
        </Link>

        <div
          className={`nav-data `}
          data-aos="fade-out"
          id="navbarSupportedContent"
        >
          <ul
            className={`navbar-nav ms-auto mb-2 mb-lg-0  ${isScrolled ? "d-none" : ""
              } `}
          >
            <li className="nav-item">
              <Link to="" className="menu-links ">
                <DropdownMenu />
              </Link>
            </li>

            <li className="nav-item">
              <Link to="" className="menu-links ">
                {/* services */}
                <DropdownMenu2 />
              </Link>
            </li>

            <li className="nav-item">
              <Link to="" className="menu-links ">
                {/* Solutions */}
                <DropdownMenu3 />
              </Link>
            </li>
            <li className="nav-item">
              <Link to="" className="menu-links " style={{ color: "white" }}>
                <Dropdownmenu5 />
              </Link>
            </li>

            {/* <li className="nav-item">
              <Link to="" className="menu-links " style={{ color: "white" }}>
                Careers
              </Link>
            </li> */}

            <li className="nav-item">
              <Link to="/about-us" className="menu-links " style={{ color: "white" }}>
                About Us
              </Link>
            </li> 

            <li className="nav-item">
              <Link
                to="/Contact"
                className="menu-links "
                style={{ color: "white" }}
              >
                Contact Us
              </Link>
            </li>
            <li className="nav-item">
              <Link
                to="/blog"
                className="menu-links "
                style={{ color: "white" }}
              >
                Blogs
              </Link>
            </li>
          </ul>
        </div>


      </nav>

      <div className="social-media-bar">


        <a href="https://www.youtube.com/@Digitec-hub" className="youtube social ">
          <FontAwesomeIcon icon={faYoutube} size="0x" />
        </a>

        <a href="https://www.instagram.com/digitechubinc/" className="instagram social">
          <FontAwesomeIcon icon={faInstagram} size="1x" />
        </a>
        <a href="https://www.twitter.com" className="twitter social">
          <FontAwesomeIcon icon={faTwitter} size="1x" />
        </a>
        <a href="https://www.facebook.com/digitechubinc" className="facebook social ">
          <FontAwesomeIcon icon={faFacebook} size="1x" />

        </a>

        <a
          href="https://www.linkedin.com/company/digitechub-inc/?viewAsMember=true"
          className="Linkedin social "
        >
          <FontAwesomeIcon icon={faLinkedin} size="1x" />
        </a>
        <a href="https://www.google.com" className="facebook social ">
          <FontAwesomeIcon icon={faGoogle} size="1x" />
        </a>
        {/* <a href="https://api.whatsapp.com/send?phone=YOUR_PHONE_NUMBER" className="whatsapp social" style={{ width: '2.9em', height: '2.3em' }}>
  <img src="/Images/icons8-whatsapp-48.png" alt="WhatsApp" />
</a> */}

        <a href="https://api.whatsapp.com/send?phone=+919999999999" className="whatsapp social">
          <FontAwesomeIcon icon={faWhatsapp} size="1x" />
        </a>

        <div id="whatsapp-popup" class="whatsapp-popup">
          <p>Click here to chat on WhatsApp</p>
        </div>




        {/* Add more social media links here */}
      </div>

      <div
        className={`${isScrolled && !sidebarOpen ? "toogle-btn-bg" : ""
          } toogle-btn start-100 `}
      >
        {/* Media Query for iPad width (1024px) and below */}
        {windowWidth <= 1024 && (
          <div
            onClick={handleToggleButtonClick}
            className={`hamburger ${sidebarOpen ? "open" : ""}`}
          >
            <div
              className={`${isScrolled && !sidebarOpen ? "var-line-scroll" : ""
                } top ver-line`}
            ></div>
            <div
              className={`${isScrolled && !sidebarOpen ? "var-line-scroll" : ""
                } middle ver-line`}
            ></div>
            <div
              className={`${isScrolled && !sidebarOpen ? "var-line-scroll" : ""
                } bottom ver-line`}
            ></div>
          </div>
        )}
      </div>

      <div
        className={`${!sidebarOpen ? "close" : ""} sidebar pt-5`}
        id="sidebar"
      >
        <div
          className="mt-5"
          onClick={() => {
            setSidebarOpen(false);
          }}
        >
          <Link to="/" className="menu-links w-100">
            <img
              src="/Images/Digitechub _SVG_file.svg"
              alt=""
              id="myImage-sidebar"
              style={{ maxWidth: "50%", marginLeft: "-10px" }}
            />
          </Link>
        </div>

        <div
          className="mt-2"
          onClick={() => {
            setSideHoverModal(false);
            setSidebarOpen(false);
          }}
          onMouseEnter={() => {
            setSideHoverModal(false);
          }}
        >
          <Link to="/" className="menu-links ">
            Home
          </Link>
        </div>
        {/* <div
          className="mt-2 "
          onClick={() => {
            setSidebarOpen(false);
          }}
          onMouseEnter={() => {
            setSideHoverModal(false);
          }}
        >
          <Link to="/about-us" className="menu-links ">
           
          </Link>
        </div> */}

        {/* <div
          onMouseEnter={() => {
            setSideHoverModal(false);
            setTimeout(() => {
              setSideHoverModal(true);
            }, 100);
            setModalPage("bussiness");
          }}
          className="mt-2"
        >
          <a className="menu-links ">Businesses</a>
        </div> */}
        <div
          className="mt-2"
          onClick={() => {
            setSidebarOpen(false);
          }}
          onMouseEnter={() => {
            setSideHoverModal(false);
          }}
        >
          {/* <Link to="/why" className="menu-links ">
            Why
          </Link> */}
        </div>

        <div
          className="mt-2"
          onClick={() => {
            setSidebarOpen(false);
          }}
          onMouseEnter={() => {
            setSideHoverModal(false);
          }}
        >
          {/* <Link to="/what" className="menu-links ">
            What
          </Link> */}
        </div>
        <div
          className="mt-2"
          onClick={() => {
            setSidebarOpen(false);
          }}
          onMouseEnter={() => {
            setSideHoverModal(false);
          }}
        >
          {/* <Link to="/How" className="menu-links ">
            How
          </Link> */}
        </div>
        <div
          className="mt-2"
          onMouseEnter={() => {
            setSideHoverModal(false);
            setTimeout(() => {
              setSideHoverModal(true);
            }, 100);
            setModalPage("product");
          }}
        >
          <a className="menu-links ">Platforms</a>
        </div>

        <div
          className="mt-2"
          onMouseEnter={async () => {
            setSideHoverModal(false);
            setTimeout(() => {
              setSideHoverModal(true);
            }, 100);
            setModalPage("services");
          }}
        >
          <a className="menu-links ">Services</a>
        </div>

        <div
          className="mt-2"
          onMouseEnter={() => {
            setSideHoverModal(false);
            setTimeout(() => {
              setSideHoverModal(true);
            }, 100);
            setModalPage("solution");
          }}
        >
          <a className="menu-links ">Solutions</a>
        </div>

        <div
          className="mt-2"
          onMouseEnter={() => {
            setSideHoverModal(false);
            setTimeout(() => {
              setSideHoverModal(true);
            }, 100);
            setModalPage("NavbarCaseStudy");
          }}
        >
          <a className="menu-links ">Case studies</a>
        </div>

        <div
          className="mt-2"
          onClick={() => {
            setSidebarOpen(false);
          }}
          onMouseEnter={() => {
            setSideHoverModal(false);
          }}
        >
          <Link to="/about-us" className="menu-links ">
            About Us
          </Link>
        </div>
        <div
          className="mt-2"
          onClick={() => {
            setSidebarOpen(false);
          }}
          onMouseEnter={() => {
            setSideHoverModal(false);
          }}
        >
          <Link to="/Contact" className="menu-links ">
            Contact Us
          </Link>



        </div>

        <div
          className="mt-2"
          onClick={() => {
            setSidebarOpen(false);
          }}
          onMouseEnter={() => {
            setSideHoverModal(false);
          }}
        >
          <Link to="/blog" className="menu-links ">
            Blogs
          </Link>



        </div>

        <div className="sidebar-nav">
          {/* <div className="mt-4">
            <Link to="/careers" className="menu-links ">
              Careers
            </Link>
          </div> */}
          {/* <div className="mt-4">
            <Link to="/investors" className="menu-links ">
              Investors
            </Link>
          </div> */}
          {/* <div className="mt-4">
            <Link to="/navigate-your-next" className="menu-links ">
              Navigate your Text
            </Link>
          </div> */}
        </div>
        <div className="">
          <h6 className="text-black" style={{ paddingTop: "20px" }}>
            Follow US
          </h6>
          <div className="d-flex flex-row mt-2" style={{ paddingTop: "20px" }}>
            <a href="https://www.youtube.com" className="youtube social ">
              <FontAwesomeIcon icon={faYoutube} size="1x" />
            </a>

            <a href="https://www.facebook.com" className="facebook social  ">
              <FontAwesomeIcon icon={faFacebook} size="1x" />
            </a>
            <a href="https://www.twitter.com" className="twitter social">
              <FontAwesomeIcon icon={faTwitter} size="1x" />
            </a>
            <a
              href="https://in.linkedin.com/company/DigitechHub-data-informatics-llp"
              className="Linkedin social "
            >
              <FontAwesomeIcon icon={faLinkedin} size="1x" />
            </a>

            {/* <a href="https://api.whatsapp.com/send?phone=YOUR_PHONE_NUMBER" className="whatsapp social" style={{ width: '2.5em', height: '2.3em' }}>
  <img src="/Images/icons8-whatsapp-48.png" alt="WhatsApp" />
 
</a> */}

            <a href="https://api.whatsapp.com/send?phone=YOUR_PHONE_NUMBER" className="whatsapp social">
              <FontAwesomeIcon icon={faWhatsapp} size="1x" />
            </a>

          </div>
        </div>
      </div>

      {sideHoverModal && (
        <SideHoverModal
          handleClick={() => {
            setSideHoverModal(!sideHoverModal);
            setSidebarOpen(false);
          }}
          page={modalPage}
        />
      )}
    </div>
  );
}